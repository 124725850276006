




























































































































































































import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadView, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IPaginate, IStore, ITransactions, IUser } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';
import WidgetMixins from '@/mixins/WidgetMixins';
import DialogMixins from '@/mixins/DialogMixins';
import moment from 'moment';
import qs from 'qs';
const transactionsModule = namespace('transactions');
const authModule = namespace('auth');
@Component({
  name: 'AllCommissions',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    GetDetails: loadView('pages/products/dialogs/GetDetails'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class AllCommissions extends mixins(WidgetMixins, DialogMixins) {
  @transactionsModule.Getter('getAllTransactions') transactions!: ITransactions[];
  @transactionsModule.Getter('getPagination') pagination!: IPaginate;
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  @transactionsModule.Getter('getTransactionDetails') commissionDetails!: ITransactions;
  @transactionsModule.Getter('getTotalCommission') totalCommission!: number;

  menu: boolean = false;
  options: any = {};
  searchTerm: string | null = null;
  date: string | null = null;
  statuses: Array<string> = ['ALL', 'SUCCESS', 'FAILED'];
  query: string = '';
  startDateMenu: boolean = false;
  endDateMenu: boolean = false;

  startDate: string | null = null;
  endDate: string | null = null;
  status: string | null = 'ALL';
  organizationId: string | null = null;

  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: 'Date',
      value: 'createdAt',
    },
    {
      text: 'Amt.',
      value: 'totalAmount',
    },
    {
      text: 'Amt. w/ charges',
      value: 'overallTotalAmount',
    },
    {
      text: 'Diff.',
      value: 'difference',
    },
    {
      text: 'Commission',
      value: 'commission',
    },
    {
      text: 'Status',
      value: 'paymentStatus',
    },
  ];

  @Watch('options', {
    deep: true,
  })
  onOptionsChange(paginate: IPaginate): void {
    this.query = `?page=${paginate.page}&size=${paginate.itemsPerPage}${
      this.status !== 'ALL' ? '&status=' + this.status : ''
    }&startDate=${this.startDate}&endDate=${this.endDate}`;
    this.$store.dispatch('transactions/listAllCommissions', this.query);
  }

  @Watch('startDate')
  onStartDateChange(date: string): void {
    this.query = `?${qs.stringify({ ...qs.parse(this.query, { ignoreQueryPrefix: true }), startDate: date })}`;
    this.$store.dispatch('transactions/listAllCommissions', this.query);
  }

  @Watch('endDate')
  onEndDateChange(date: string): void {
    this.query = `?${qs.stringify({ ...qs.parse(this.query, { ignoreQueryPrefix: true }), endDate: date })}`;
    this.$store.dispatch('transactions/listAllCommissions', this.query);
  }

  downloadFile(ext: string): void {
    const query = this.query + `&download=true&ext=${ext}`;
    this.$store.dispatch('transactions/listAllCommissions', query);
  }

  created(): void {
    this.$store.dispatch('auth/me');
    this.startDate = moment()
      .subtract(3, 'month')
      .format('YYYY-MM-DD');
    this.endDate = moment().format('YYYY-MM-DD');

    if (this.$route.query.page && this.$route.query.size && this.$route.query.ext) {
      this.query = `?${qs.stringify({ ...this.$route.query, startDate: this.startDate, endDate: this.startDate })}`;
      this.$store.dispatch('transactions/listAllCommissions', this.query);
    } else {
      this.query = `?page=1&size=15&startDate=${this.startDate}&endDate=${this.endDate}`;
      this.$store.dispatch('transactions/listAllCommissions', this.query);
    }
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
